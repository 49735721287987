/*
 * Various accessibility things that need to happen.
 */
function accessibilityShim() {
  var high_contrast = null;

  // Add alt text to Google's map control images.
  document.querySelectorAll(`img`).forEach(function(image) {
    image.getAttribute("")
    switch (image.getAttribute("src")) {
      case "https://maps.gstatic.com/mapfiles/undo_poly.png":
        image.setAttribute("alt", "Undo");
        break;
      case "https://maps.gstatic.com/mapfiles/api-3/images/google_white5.png":
        image.setAttribute.attr("alt", "Google");
        break;
      case "https://maps.gstatic.com/mapfiles/api-3/images/mapcnt6.png":
        image.setAttribute.attr("alt", "Close");
        break;
      case "https://maps.gstatic.com/mapfiles/api-3/images/sv9.png":
        // TODO: What is this really?
        image.setAttribute.attr("alt", "Map selection cursor");
        break;
      case "https://maps.gstatic.com/mapfiles/api-3/images/tmapctrl.png":
        if (parseInt(image.style.top) == 0) {
          image.setAttribute.attr("alt", "Zoom in");
        } else {
          image.setAttribute.attr("alt", "Zoom out");
        }
        break;
      case "https://maps.gstatic.com/mapfiles/api-3/images/cb_scout5.png":
        image.setAttribute.attr("alt", "Pegman");
        break;
      case "https://maps.gstatic.com/mapfiles/api-3/images/tmapctrl4.png":
        if (parseInt(image.style.top) == 6) {
          image.setAttribute.attr("alt", "Rotate map 90 degrees");
        } else {
          image.setAttribute.attr("alt", "Tilt map");
        }
        break;
    }
  });

  // Look for high-contrast situations; we may need to adjust things
  if (document.querySelector("html").getAttribute("hc")) {
    // Detect the high-contrast plugin for Chrome, regardless of OS
    high_contrast = "chrome-" + document.querySelector("html").getAttribute("hc");
  } else if (navigator.appVersion.indexOf("Win") != -1) {
    // Detect if Windows is in high contrast mode
    // Adapted from https://gist.github.com/nfreear/c82581b4485cd303150d
    var hcm = generateElements("<p style='position:absolute;top:0;left:-999px;background-color:#878787;'>MHC</p>");
    var test = hcm[0].style.backgroundColor.toLowerCase();
    if (test !== "#878787" && test !== "rgb(135, 135, 135)") {
      high_contrast = "windows";
    }
  }
  // Log the value in GTM if we found something above.
  if (high_contrast) {
    (window.dataLayer = window.dataLayer||[]).push({
      'event': "demographicVariableLoad",
      demographicVariableLoad: {
        name: "high-contrast",
        value: high_contrast
      }
    });
  }
}

/**
 * Adds the building or place's name, links, and search items to the
 * given search list. mapitem is the building or place
 */
function addSearchTermsToIndex(mapitem, featureId, searchIndex) {
  var searchTerms = [];
  searchTerms.push( mapitem.title.replace(/&nbsp;/g, " ") );
  Object.keys(mapitem.links).forEach(function(term) {
    if (searchTerms.indexOf(term) == -1) {
      searchTerms.push(term);
    }
  });
  mapitem.search.forEach(function(term) {
    if (searchTerms.indexOf(term) == -1) {
      searchTerms.push(term);
    }
  });
  for (var i in searchTerms) {
    var searchId = featureId + "~" + i;
    searchIndex.push({ id: searchId, search: searchTerms[i] });
  }
  return searchTerms.slice(0); // return a copy of the array of strings
}

/**
 * Called if an AJAX request for content fails.
 */
function contentRequestFailure(jqXHR, textStatus, errorThrown) {
  console.log("Unable to fetch data: " + errorThrown);
}

/**
 * Create a link in the content link list.
 */
function createContentLink(title, url) {
  var place_link = "<li><a href='" + url + "' target='_blank'>" + title + "</a></li>";
  document.querySelector("#place-content ul").append(generateElements(place_link)[0]);
}

/**
 * Creates a list element with a link to a location that can then be added
 * to a list. The link registers the engagement with GTM.
 */
function createLocationLink(featureId, urlSlug, title, context) {
  var feat_link = generateElements("<li>" + title + "</li>")

  // If we have a feature ID, set up desired behavior.
  if (featureId !== "") {
    feat_link[0].classList.add(`link-feat-${urlSlug}`);
    feat_link[0].addEventListener('click', () => {
      var feature = map.data.getFeatureById(featureId);
      // Select the feature.
      selectFeature(feature, true /* shouldUpdateURL */);
      feature.getProperty("label").pin();
      recordLinkSelection(context, feature.getProperty("url_slug"));
    });
  }

  return feat_link[0];
}

/**
 * Creates a list element with a link to a location that can then be added
 * to a list. The link registers the engagement with GTM.
 */
function createGroupLink(groupfeature, context) {
  var feat_link = generateElements("<li>" + groupfeature.getProperty("title") + "</li>");

  // Get features in group
  var features = []
  var building_ids = groupfeature.getProperty("buidlings");
  for (i in building_ids) {
    var feature = map.data.getFeatureById(building_ids[i]);
    features.push(feature)
  }

  // If we have a feature ID, set up desired behavior.
  if (features != null) {
    feat_link[0].addClass("link-feat-" + groupfeature.getProperty("url_slug"));
    feat_link[0].addEventListener('click', () => {
      // Select the feature.
      selectFeatures(groupfeature);

      // Show building content.
      updateContentSection(groupfeature);

      recordLinkSelection(context, groupfeature.getProperty("url_slug"));
    });
  }

  return feat_link[0];
}

function recordLinkSelection(context, urlSlug) {
  if (context == "search-results") {
    // Send searchEngagement to GTM instead of menuEngagement.
    (window.dataLayer = window.dataLayer||[]).push({
      'event': "searchEngagement",
      searchEngagement: {
        action: "selection",
        value: urlSlug,
        data: map.params.search,
        options: getSelectedOptions()
      }
    });
    // Cancel future abandonment events until there’s a new search.
    map.search.active = false;
  } else {
    // If there is an active search but user clicked a menu link instead,
    // record a search abandonment event.
    if (map.search.active) {
      (window.dataLayer = window.dataLayer||[]).push({
        'event': "searchEngagement",
        searchEngagement: {
          action: "abandon",
          data: map.search.term
        }
      });
    }
    // Regardless of active search, record that the user selected a place.
    (window.dataLayer = window.dataLayer||[]).push({
      'event': "menuEngagement",
      menuEngagement: {
        name: "search_places",
        action: "selection",
        value: context, // which submenu was the link in?
        data: urlSlug,
        options: getSelectedOptions()
      }
    });
  }
}

/*
 * Build a comma-separated list of selected options to pass to GTM.
 */
function getSelectedOptions() {
  var opts = [];
  for (var layer in map.params.options) {
    if (map.params.options[layer]) {
      opts.push(layer);
    }
  }
  opts.sort();
  return opts.join();
}

/*
 * Get starting values for the map from the URL, or reasonable defaults.
 */
function parseURL(knownLayers) {
  // Set some defaults.
  var params = {
    center: { lat: 42.256, lng: -72.574 },
    zoom: (document.body.clientWidth > 480) ? 17 : 16,
    fid: null,
    feature: null,
    search: "",
    options: {},
    return: { // Need to return things to map before we have a map global.
      search: { active: false, term: "" }
    }
  }
  knownLayers.forEach(function(layer) {
    if (layer != "inactive_buildings") {
      params.options[layer] = false;
    }
  })

  // If there’s nothing to parse, we’re done.
  if (!(window.location.hash)) {
    return params;
  }

  function removeElementFromHashstring(element, hashstring) {
    var start = hashstring.indexOf(element+"=");
    if (start > 0 && (hashstring.charAt(start - 1) == "&")) {
      start = start - 1;
    }
    var end = hashstring.indexOf("&", start + 1);

    var newhashstring = hashstring.substring(0, start);
    // If the zoom parameter was at the end, we won't have any parameters after it.
    if (end >= 0) {
      newhashstring += hashstring.substring(end);
    }
    if (newhashstring.charAt(0) == "&") {
      newhashstring = newhashstring.substring(1);
    }
    if (window.history.replaceState) {
      window.history.replaceState({}, '', "#" + newhashstring);
    }
    else {
      // Use location.hash as a fallback.
      window.location.hash = newhashstring;
    }

    return newhashstring;
  }

  var hashstring = window.location.hash.substring(1);
  var hasharray = hashstring.split("&");
  var hashdict = {};
  hasharray.map((elt) => {
    var arr = elt.split("=");
    hashdict[arr[0]] = arr[1];
  });

  if (hashdict.ll) {
    var ll = hashdict.ll.split(",");
    params.center = { lat: parseFloat(ll[0]), lng: parseFloat(ll[1]) };
  }

  // Translate place (must be a known slug) to feature ID for initial feature selection.
  if (hashdict.place && hashdict.group) {
    hashstring = removeElementFromHashstring("group", hashstring);
  }
  if (hashdict.place) {
    if (redirect_slugs[hashdict.place]) {
      hashdict.place = redirect_slugs[hashdict.place];
      // Fix the slug in the URL.
      var pStart = hashstring.indexOf("place=") + 6;
      var pEnd = hashstring.indexOf("&", pStart);
      var newHash = hashstring.substring(0, pStart) + hashdict.place;
      if (pEnd >= 0) {
        newHash += hashstring.substring(pEnd);
      }
      if (window.history.replaceState) {
        window.history.replaceState({}, '', "#" + newHash);
      }
      else {
        window.location.hash = newHash;
      }
    }
    if (url_slugs[hashdict.place]) {
      params.fid = url_slugs[hashdict.place];
    }
  }
  else if (hashdict.group && group_slugs.indexOf(hashdict.group) >= 0) {
    params.gid = hashdict.group;
  }

  if (hashdict.q) {
    var searchTerm = decodeURIComponent(hashdict.q);
    params.search = sanitizeSearchTerm(searchTerm);
    params.return.search.term = params.search;
  }

  if (hashdict.options) {
    var optList = hashdict.options.split(",");
    optList.forEach(function requestOption(opt) {
      if (knownLayers.indexOf(opt) > -1) {
        // If this is a known layer, show it once the geodata is loaded.
        params.options[opt] = true;
        // ... and select its checkbox in the Options panel.
        document.getElementById(`opt-${opt}`).checked = true;
      }
    });
  }

  if (hashdict.z) {
    params.zoom = parseInt(hashdict.z);

    // Remove the zoom parameter from the URL, since we never use it again and
    // we don't reset it. This handles all of the possible locations of "z=#",
    // where it might be at the beginning, middle, or end of the string.
    hashstring = removeElementFromHashstring("z", hashstring);
  }

  return params;
}

/**
 * Given the content for a particular building, add it to any of the category
 * lists it applies to.
 */
function processLocationCategory(featureId, building) {
  var title = building.title;

  // Match the category to the right heading to put it in.
  // A location may have multiple categories.
  var categories = building.category;
  categories.forEach(function (category) {
    if (category == "academic" || category == "residence" || category == "other" || category == "poi") {
      // We need to create a new link for each of the lists we want to add this location to.
      var feat_link = createLocationLink(featureId, building.url_slug, title, category);
      document.querySelector(`#${category} ul`).append(feat_link);
    }
  });
}

/**
 * Add links to locations under the appropriate headings, create labels, and
 * merge building content into the map features. This is called by
 * mergeMapContent() once the building content and geodata exist.
 */
function processLocations(buildingContent, searchList) {
  // Remove the "Loading..." placeholders from the lists.
  document.querySelectorAll("ul.loading").forEach(function (element) { element.replaceChildren(); });
  document.querySelectorAll("ul.loading").forEach(function (element) { element.classList.remove("loading"); });

  // --- SORT THE BUILDINGS ---
  // Sort in order, per JavaScript rules. This is numbers by a naive sort (8 is returned before 10) first, then letters.
  var presortedBuildings = Object.entries(buildingContent).sort(sortByLITSName());

  // Find the point where the list switches from numbers to letters
  var splitPoint = 0;
  var indexOf1837 = 0;
  for (var index in presortedBuildings) {
    if (presortedBuildings[index][0] == "building_D15") {
      indexOf1837 = index;
    }
    else if(!presortedBuildings[index][1].title.match(/^\d/)) {
      splitPoint = index;
      break;
    }

  }
  // Split on the inflection point and combine so that numbers come after letters, EXCEPT 1837 which needs to be before the alpha buildings.
  // So, desired result: 1837, all of the buildings starting with a-z, all of the other buildings starting with 0-9
  var alphaBuildings = presortedBuildings.slice(splitPoint);
  var numericBuildings = presortedBuildings.slice(0,splitPoint); // Should we wish to sort in natural order, this is probably the spot to do so.
  const entryFor1837 = [numericBuildings[indexOf1837]];
  var sortedBuildings = entryFor1837.concat(alphaBuildings).concat(numericBuildings.toSpliced(indexOf1837, 1));
  // --- done sorting the buildings ---

  // Loop through each object and process it.
  for (var id in sortedBuildings) {
    var featureId = sortedBuildings[id][0];
    var building = sortedBuildings[id][1];
    var feature = map.data.getFeatureById(featureId);

    // Remove excluded buildings from all future consideration.
    if (building.category.indexOf("excluded") > -1) {
      continue;
    }

    // Keep ancillary buildings quiet: present as features, but not in the
    // menus and not searchable.
    if ("primary" in building) {
      feature.setProperty("primary", building.primary);
      styleAncillaryFeature(feature)
      continue;
    }

    // If a building has ancillaries defined, they should be
    // treated as part of the same feature.
    if ("ancillaries" in building) {
      feature.setProperty("ancillaries", building.ancillaries);
    }
    // Add this location to the appropriate category list.
    processLocationCategory(featureId, building);

    // Set some empty defaults
    if (!("links" in building)) {
      building.links = {};
    }
    if (!("search" in building)) {
      building.search = [];
    }
    // Add this building to our search index.
    // We give each search term / seed its own unique id so that we can
    // identify which seed led to a building being included in the search
    // results. For example, for Dwight Hall, its first search term would
    // be "Dwight Hall" with an id of "building_G07~0".
    searchTerms = addSearchTermsToIndex(building, featureId, searchList);

    // Add the content as properties on the feature. We don't care about
    // category, since it's only used during initialization.
    feature.setProperty("title", building.title);
    feature.setProperty("url_slug", building.url_slug);
    feature.setProperty("description", building.description);
    feature.setProperty("links", building.links);
    feature.setProperty("search", searchTerms);

    // Create the label for this location. This also adds the label and label
    // visibility as properties on the feature.
    createLabel(featureId, feature, building);
  }

  // Show default labels for current zoom.
  showLabelsByZoom();
}


function processGroups(groupContent, searchList) {
  // Loop through each object and process it.
  for (var featureId in groupContent) {
    var group = groupContent[featureId];

    // Add this location to the appropriate category list.
    var title = group.title;

    // Set some empty defaults
    if (!("links" in group)) {
      group.links = {};
    }
    if (!("markers" in group)) {
      group.markers = [];
    }
    if (!("search" in group)) {
      group.search = [];
    }

    if (("searchable" in group) && group.searchable) {
      searchTerms = addSearchTermsToIndex(group, featureId, searchList);
    }

    var feature = map.data.add(new google.maps.Data.Feature({id:featureId}));
    feature.setProperty("title", group.title);
    feature.setProperty("buildings", group.buildings);
    feature.setProperty("url_slug", group.url_slug);
    feature.setProperty("description", group.description);
    feature.setProperty("links", group.links);
    feature.setProperty("markers", group.markers);
    feature.setProperty("search", searchTerms);

    // Match the category to the right heading to put it in.
    // A location may have multiple categories.
    var categories = group.category;
    categories.forEach(function(category) {
      if (category == "academic" || category == "residence" || category == "other" || category == "poi") {
        // We need to create a new link for each of the lists we want to add this location to.
        var feat_link = createGroupLink(feature, category);
        document.querySelector(`#${category} ul`).append(feat_link);
      }
    });
  }
}

/*
 * Update the current URL so that the map view can be linked to.
 */
function updateURL() {
  // Encode the map center, current search term, and the current feature that is selected.
  var center = map.getCenter();

  // URL scheme: map.local/#lat=42&lng=72&place=communitycenter&q=search%20term&options=opt1,opt2
  var hashstring = "ll=" + center.lat().toFixed(3) + "," + center.lng().toFixed(3);

  // Every feature maps to a human-readable URL slug.
  if (map.params.feature) {
    var currentPlace = map.params.feature.getProperty("url_slug");
    if (currentPlace !== "") {
      hashstring += "&place=" + currentPlace;
    }
  }

  if (map.params.features && map.params.group) {
    var current_place = map.params.group.getProperty("url_slug");
    if (current_place !== "") {
      hashstring += "&group=" + current_place;
    }
  }

  if (map.params.search !== "") {
    hashstring += "&q=" + encodeURIComponent(map.params.search);
  }

  var options = [];
  for (var opt in map.params.options) {
    if (map.params.options[opt]) {
      options.push(opt);
    }
  }
  if (options.length > 0) {
    // If any layers are visible, add them to the URL (minus the trailing comma).
    hashstring += "&options=" + options.join(",");
  }

  // We use pushState to create browser history events; we can also use
  // replaceState as an alternative to not clog up the browser history.
  if (window.history.pushState) {
    window.history.pushState({}, '', "#" + hashstring);
  }
  else {
    // Use location.hash as a fallback.
    window.location.hash = hashstring;
  }
}

/**
 * Replacement for jQuery .is(':visible')
 * @param {*} el 
 * @returns 
 */
function isVisible(el) {
  return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
}

/**
 * Replacement for creating elements in jQuery with things like $('<div>Hello World!</div>');
 * @param {*} html 
 * @returns 
 */
function generateElements(html) {
  const template = document.createElement('template');
  template.innerHTML = html.trim();
  return template.content.children;
}

/**
 * Sorts by the building title field
 * @returns the sort order
 */
function sortByLITSName() {
  return function (a, b) {
    if (a[1].title > b[1].title) {
      return 1;
    }
    else if (a[1].title < b[1].title) {
      return -1;
    }
    return 0;
  }
}
/**
 * Replacement for jQuery $(el).width(val)
 * @param {*} el 
 * @param {*} val 
 */
function setWidth(el, val) {
  if (typeof val === 'function') val = val();
  if (typeof val === 'string') el.style.width = val;
  else el.style.width = val + 'px';
}

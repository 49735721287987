/*
 * Building colors should be suppressed when in satellite view.
 */
function buildingStyleDefault(type) {
  // Default settings
  var style = { // google.maps.Data.StyleOptions
    fillColor: "#6d635e",
    fillOpacity: 0.36,
    strokeColor: "#6d635e",
    strokeWeight: 0,
    visible: true,
    clickable: true
  };

  // Amend under certain conditions.
  if (map.mapTypeId == "satellite") {
    style.fillOpacity = 0;
  }

  // Tweak for inactive buildings.
  if (type == "inactive") {
    var override = buildingStyleOverride(type);
    for (var option in override) {
      style[option] = override[option];
    }
    style.clickable = false;
  }
  return style;
}

/*
 * Create style objects suitable over overriding default building style.
 */
function buildingStyleOverride(type) {
  var style = {};
  // If we were called with a known override type, describe the new style.
  switch(type) {
    case "inactive":
      style.fillColor = "#6d635e";
      style.fillOpacity = 0.18;
      break;
    case "highlight":
      style.fillColor = "#0ae";
      style.fillOpacity = 0.6;
      break;
  }
  return style;
}

/**
 * This is called by map.bus_stops.setStyle() once for each bus stop feature.
 * We set the icon each time instead of using overrideStyle() to change
 * only the visibility, because we need to re-set this for every feature anways.
 */
function busStopsStyleFunction(layerVisible, feature) {
  return {
    icon: {
      url: "/img/" + feature.getProperty("type") + "_icon.png",
      anchor: new google.maps.Point(12.5, 12.5)
    },
    visible: layerVisible,
    clickable: false
  };
}

/**
 * Default style settings for the no smoking area
 */
function noSmokingAreaStyleFunction(layerVisible) {
  // Default settings
  var style = {
    fillColor: "#004876",
    fillOpacity: 0.5,
    strokeColor: "#004876",
    strokeWeight: 1,
    visible: layerVisible,
    clickable: false
  };

  return style;
}


/*
 * Set the styles on the data layers.
 */
function initLayerStyle(layer) {
  switch(layer) {
    case "blue_lights":
    case "entrances":
      map[layer].setStyle({
        icon: {
          url: "/img/" + layer + "_icon.png",
          anchor: new google.maps.Point(12.5, 12.5)
        },
        visible: map.params.options[layer],
        clickable: false
      });
      break;
    case "bus_stops":
      map.bus_stops.setStyle(
        busStopsStyleFunction.bind(null, map.params.options["bus_stops"] /* layerVisible */));
    case "inactive_buildings":
      var style = buildingStyleDefault("inactive");
      map.inactive_buildings.setStyle(style);
      break;
    case "parking":
      // Parking style varies based on zoom level.
      map.parking.setStyle(
        parkingStyleFunction.bind(null, map.params.options["parking"] /* layerVisible */));
      break;
    case "no_smoking":
      map.no_smoking.setStyle(
        noSmokingAreaStyleFunction(map.params.options["no_smoking"])
      );
      break;
  } // end of switch(layer)
}

/*
 * Returns an Array<MapTypeStyle> to style the base map. For now, this is only
 * called once, by initMap(), but future work may offer a high-contrast
 * version which could be indicated with a parameter.
 */
function makeMapStyle() {
  var mapStyle = [
    { // Turn off labels on Google’s predefined points of interest.
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    }, { // Show College land lighter grey than default.
      featureType: "poi.school",
      elementType: "geometry",
      stylers: [{ color: "#ebe7df" }]
    }, { // We have our own bus stop icons.
      featureType: "transit.station.bus",
      stylers: [{ visibility: "off" }]
    }, { // We have our own building outlines.
      featureType: "landscape.man_made",
      elementType: "geometry",
      stylers: [{ visibility: "off" }]
    }, { // Building names too?
      featureType: "landscape.man_made",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    }, { // Don’t show land parcels.
      featureType: "administrative.land_parcel",
      stylers: [{ visibility: "off" }]
    }, { // We want paths and local roads to be white.
      featureType: "road.local",
      elementType: "geometry.fill",
      stylers: [{ color: "#ffffff" }]
    }
  ];

  return mapStyle;
}

/*
 * This is called by map.parking.setStyle() once for each parking feature.
 * We set the icon each time instead of using overrideStyle() to change
 * only the visibility because setStyle() is far easier than tracking which
 * overrides should be changing (or even testing all of them) with every
 * change in zoom level.
 *
 * Refer to parking_clusters.js for the visibility rule.
 */
function parkingStyleFunction(layerVisible, feature) {
  var style;

  switch(feature.getProperty("type")) {
    case "parking":
      var category = feature.getProperty("category");
      var middle = (category == "visitor") ? 11 : 12.5;
      style = {
        icon: {
          url: "/img/" + category + "_parking_icon.png",
          anchor: new google.maps.Point(12.5, middle)
        },
        // If the layer itself shouldn't be visible, don't show it; otherwise
        // check the feature's individual visibility.
        visible: layerVisible ? getParkingVisibility(feature.getId()) : false,
        clickable: false
      };
      break;
    case "parking_lot":
      style = {
        fillColor: "#f61",
        fillOpacity: 0.18,
        strokeWeight: 0,
        visible: true, // Lots are always visible
        clickable: false
      };
      break;
  }
  return style;
}

/*
 * Set the visibility of a data layer.
 */
function setLayerVisibility(layer, visibility, shouldUpdateURL) {
  // We keep track of the visibility of the layer for use in tracking
  // the current map status in the URL.
  map.params.options[layer] = visibility;

  switch(layer) {
    case "parking":
      // Parking style varies based on zoom level; we have to re-test every time.
      map.parking.setStyle(parkingStyleFunction.bind(null, visibility));
      break;
    case "bus_stops":
      // Bus stops style varies based on feature type.
      map.bus_stops.setStyle(busStopsStyleFunction.bind(null, visibility));
      break;
    default:
      // Get the current style and change the "visible" option.
      // We would use overrideStyle, but it applies to a specific feature.
      var style = map[layer].getStyle();
      style.visible = visibility;
      map[layer].setStyle(style);
      break;
  }

  if (shouldUpdateURL) {
    updateURL();
  }
}

/*
 * Set the style on ancillary buildings based on whether parent is selected.
 * We use the "highlighted" property because, by styling the features with an
 * object instead of a function reference, we can't test the intended style
 * of a particular feature. TODO: consider benchmarking whether our current
 * approach is actually more efficient.
 */
function styleAncillaryFeature(target) {
  if (map.params.feature && target.getProperty("primary") == map.params.feature.getId()) {
    map.data.overrideStyle(target, buildingStyleOverride("highlight"));
    target.setProperty("highlighted", true);
  } else {
    map.data.overrideStyle(target, buildingStyleOverride("inactive"));
  }
}

/**
 * Toggle the visibility of the list of links in a submenu.
 */
function toggleCategorySubmenu(categoryDiv, show) {
  toggle = categoryDiv.querySelector(`:scope h2`);
  container = categoryDiv.querySelector(`:scope ul`);

  // Toggle the given categoryDiv open/closed; add/remove the "open" class
  if (show) {
    openMenu(toggle, container);
    categoryDiv.classList.add("open");
  }
  else {
    closeMenu(toggle, container);
    categoryDiv.classList.remove("open");
  }
  document.getElementById("places").style.height = "auto";
}

/**
 * Open the menu indicated by the menuSlug and rotate the svg.
 * Close the other menu and rotate the svg if open.
 * Register GTM event if the menuSlug menu is open after toggling.
 *
 * @param {*} menuSlug
 */
function togglePlacesAndOptionsMenus(activeMenuId, gtmCrap) {

  const placesToggle = document.getElementById("places-link");
  const placesContainer = document.getElementById("places");
  const optionsToggle = document.getElementById("options-link");
  const optionsContainer = document.getElementById("options");

  var activeToggle      = placesToggle;
  var activeContainer   = placesContainer;
  var inactiveToggle    = optionsToggle;
  var inactiveContainer = optionsContainer;
  if (activeMenuId == "options") {
    activeToggle      = optionsToggle;
    activeContainer   = optionsContainer;
    inactiveToggle    = placesToggle;
    inactiveContainer = placesContainer;
  }

  // Close places and options
  // Toggle the activeMenuId
  closeMenu(inactiveToggle, inactiveContainer);

  if (isMenuOpen(activeContainer)) {
    closeMenu(activeToggle, activeContainer);
    activeContainer.classList.remove("open");
  }
  else {
    openMenu(activeToggle, activeContainer);
    activeContainer.classList.add("open");
  }
  inactiveContainer.classList.remove("open");
}

/**
 * Tests if the given menu is already open
 *
 * @param {*} menu
 * @returns true if open, false otw
 */
function isMenuOpen(menu) {
  menuHeight = menu.style.height;
  if (!menuHeight || menuHeight == "0px") {
    return false;
  }
  return true;
}

/**
 * Open menu
 *
 * @param {*} toggle
 * @param {*} container
 */
function openMenu(toggle, container) {
  container.style.height = `${container.scrollHeight}px`;
  // Rotate toggle
  toggle.querySelector(`:scope svg`).style.transform = "rotate(-180deg)";
}

/**
 * Close menu
 *
 * @param {*} toggle
 * @param {*} container
 */
function closeMenu(toggle, container) {
  if (!container.style.height || container.style.height == "0px") {
    return;
  }
  container.style.height = `0px`;
  
  // Rotate toggle
  toggle.querySelector(`:scope svg`).style.transform = "rotate(0deg)";
}

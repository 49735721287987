// Label Settings
//
// Labels are applied to all buildings that aren't marked as "excluded" in
// building_content.json source data.
//
// If the building's definition in that file includes a non-empty "label"
// field, that will be used for the text of the label. HTML is allowed.
// If there is no field (or if it is empty), the "title" field is used.
//
// If the building's definition includes a "labelVisibility" field, it is
// used to specify at which zoom levels the building is always labeled.
// If not, the variables below set the defaults: all buildings are shown
// at zoom=19 or greater; only some are shown at 18 or 17.
var default_label_zoom = 19;
var label_visibility = {
  "building_A54": 17, // Willits-Hallowell
  "building_G02": 17, // Community Center
  "building_G10": 17, // Kendall
  "building_G14": 17, // Mary Woolley
  "building_G20": 17, // Skinner
  "building_G22": 17, // Williston Library
  "building_G25": 17, // Art
  "building_G29": 17, // Equestrian Center
  "building_G32": 17, // Newhall (Admission)

  "building_G13": 18 // Mary Lyon
}

/**
 * Defines a class to use for feature labels.
 * Adapted from http://stackoverflow.com/questions/3953922
 */
function HtmlOverlay(location, content, url_slug, visibility) {
  this.location = location;
  this.content = content;
  this.url_slug = url_slug;
  this.visibility = visibility;
  this.pinned = false;
  this.grouped = false;

  // Overlay goes in its own div, which is created when it's added to the map
  this.div = null;
}

/**
 * HtmlOverlay gets superclassed in initMap(), which then calls this to add methods.
 * Adapted from http://stackoverflow.com/questions/3953922
 */
function addOverlayMethods(html_overlay) {

  // This is called when the map panes are ready for overlays.
  html_overlay.prototype.onAdd = function() {

    var div = document.createElement('div');
    div.className = "label-overlay";
    div.innerHTML = this.content;
    div.id = "label-" + this.url_slug;

    // Set the overlay's div_ property to this DIV
    this.div = div;
    var overlayProjection = this.getProjection();
    var position = overlayProjection.fromLatLngToDivPixel(this.location);
    div.style.left = position.x + 'px';
    div.style.top = position.y + 'px';

    // We add an overlay to a map via one of the map's panes.
    var panes = this.getPanes();
    panes.floatPane.appendChild(div);
  }

  // This is called when the label overlay object is added to the map.
  html_overlay.prototype.draw = function() {
    var overlayProjection = this.getProjection();

    // Retrieve the southwest and northeast coordinates of this overlay
    // in latlngs and convert them to pixels coordinates.
    // We'll use these coordinates to resize the DIV.
    var position = overlayProjection.fromLatLngToDivPixel(this.location);

    // The point for each building is its centroid; this accounts for
    // the size of the div to center it horizonally and vertically on
    // that point (usually; occasionally, but not repeatably, it's
    // misaligned). If the label is pinned, don't center it vertically,
    // which will make the top of the text line up with the point of the pin.
    var div = this.div;
    var widthOffset = div.offsetWidth / 2;
    var heightOffset = this.pinned ? 0 : div.offsetHeight / 2;
    div.style.left = position.x - widthOffset + 'px';
    div.style.top = position.y - heightOffset + 'px';
  }

  // This is called when the object is removed from the map (that is,
  // setMap(null), which is different from hiding it).
  html_overlay.prototype.onRemove = function() {
    this.div.parentNode.removeChild(this.div);
    this.div = null;
  }

  // Overlays are detached by default; first attempt to show is really add.
  html_overlay.prototype.show = function() {
    if (!this.div) {
      this.setMap(map);
    } else {
      this.draw();
      this.div.style.visibility = "visible";
    }
  }

  // Keep the label attached to the map so we don't have to run onAdd().
  html_overlay.prototype.hide = function() {
    if (this.div) {
      this.div.style.visibility = "hidden";
    }
  }

  // If the building is pinned, make sure label is shown in the right place.
  html_overlay.prototype.pin = function() {
    this.pinned = true;
    this.show();
  }

  // If the building's pin is removed, hide its label unless it's supposed
  // to be showing because of the current zoom.
  html_overlay.prototype.unpin = function() {
    this.pinned = false;
    var zoom = map.getZoom();
    if (zoom >= this.visibility) {
      this.draw();
    } else {
      this.hide();
    }
  }

  // Set a flag to retain a label shown because the feature is in a group.
  html_overlay.prototype.group = function() {
    this.grouped = true;
    this.show();
  }
  // If the group is deselected, hide its label unless it's supposed
  // to be showing because of the current zoom.
  html_overlay.prototype.ungroup = function() {
    this.grouped = false;
    var zoom = map.getZoom();
    if (zoom >= this.visibility) {
      this.draw();
    } else {
      this.hide();
    }
  }
}

/**
 * Create the label for a specific feature. This is called by
 * processLocations(). Once created, we store the label as a property on the
 * feature object for easy reference.
 */
function createLabel(featureId, feature, building) {
  // Find the centroid.
  var pt = feature.getProperty("click_point");
  var ll = new google.maps.LatLng(pt.coordinates[1], pt.coordinates[0]);

  var labelText = ("label" in building) ? building.label : building.title;
  labelText = labelText.replace(/ /g, "<br>");

  // Create the overlay object.
  var visibility = building.labelVisibility || label_visibility[featureId] || default_label_zoom;
  var url_slug = building.url_slug;
  var label = new HtmlOverlay(ll, labelText, url_slug, visibility);
  feature.setProperty("label", label);
  feature.setProperty("labelVisibility", visibility);

  // Show the label if this feature is selected.
  if (feature == map.params.feature) {
    label.pin();
  }
}

/**
 * Called by processLocations(), whenever the full building content is being
 * loaded (such as initialization), and also called by a listener for changes
 * in map zoom.
 *
 * For now, this tests all the labels to determine the new visibility.
 * We could potentially optimize performance by storing deltas instead of
 * individual lookups, but with fewer than 100 features, that's probably
 * more trouble than it's worth.
 */
function showLabelsByZoom() {
  map.data.forEach(function(feature) {
    var label = feature.getProperty("label");
    if (label) {
      if ((map.params.zoom >= feature.getProperty("labelVisibility"))
          || label.pinned || label.grouped) {
        label.show();
      } else {
        label.hide();
      }
    }
  });
}

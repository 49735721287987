var fuseSearch = null;
var quickSearchLinks = {
  // title: [featureID, urlSlug]
  "Admission": ["building_G32", "admission"],
  "Art Museum": ["building_G25", "art"],
  "Community Center": ["building_G02", "communitycenter"],
  "Chapin Auditorium": ["building_G14", "mary-woolley"],
  "Equestrian Center": ["building_G29", "equestrian"],
  "Fimbel Maker & Innovation Lab": ["building_D23", "prospect"],
  "Hooker Auditorium": ["building_G05", "clapp"],
  "Library / LITS": ["building_G22", "library"],
  "Willits-Hallowell Conference Center": ["building_A54", "willits"]
}

/**
 * Flush everything related to the search: clear the current results list,
 * clean out the URL, and log with GTM if appropriate.
 */
function abandonSearch() {
  clearSearchList();
  map.params.search = "";
  updateURL();
  if (map.search.active && map.search.term != "") {
    (window.dataLayer = window.dataLayer||[]).push({
      'event': "searchEngagement",
      searchEngagement: {
        action: "abandon",
        data: map.search.term
      }
    });
  }
  map.search.active = false;
  map.search.term = "";
}

/**
 * Clear the list of search results or quick search links.
 */
function clearSearchList() {
  document.querySelector("#search ul").replaceChildren();
  document.querySelector("#search ul").style.display = "none";
}

/**
 * Create links to commonly-searched items.
 */
function createQuickSearchLinks() {
  // Get rid of the "loading..." text.
  document.querySelector("#common-searches ul").replaceChildren();

  // Display links.
  for (var title in quickSearchLinks) {
    document.querySelector("#common-searches ul").append(
      createLocationLink(quickSearchLinks[title][0] /* featureID */,
        quickSearchLinks[title][1] /* urlSlug */, title, "common-searches"));
  }
}

/**
 * Initialize our search index.
 */
function initSearch(buildingSearchList) {
  // The list of building search keys is built in processLocations.
  // We create a Fuse object to search on.
  fuseSearch = new Fuse(buildingSearchList, {
    keys: [ "search" ],
    id: "id",
    includeScore: true,
    threshold: 0.4 // Lower than default to reduce extraneous matches.
  });
}

/**
 * Given a search term, perform a search show the top 10 or fewer results.
 */
function performSearch(term) {
  // If we don't have a search index yet, we can't search.

  if (fuseSearch === null) {
    showResults([]);
  } else {
    map.params.search = sanitizeSearchTerm(term);
    var results = fuseSearch.search(map.params.search);
    showResults(results);
  }
}

/*
 * Clean up the search term for safety
 */
function sanitizeSearchTerm(term) {
  // Search term should only be alphanumeric and internal spaces.
  term = term.replace(/[^\w\s]/gi, ' ');
  term = term.replace(/^\s+/, '');
  term = term.replace(/\s+$/, '');
  return term.substring(0, 32); // We have a maximum length of 32 characters.
}

/**
 * Given a list of building IDs, show the results in the page.
 */
function showResults(results) {
  clearSearchList();

  document.querySelector("#search ul").classList.add("search-results");
  document.querySelector("#search ul").style.display = "block";

  if (results.length > 0) {
    // We pre-process the search results to collect each term related to an
    // individual building.
    var resultsMap = {};
    results.forEach(function(searchId) {
      var res = searchId.item.id.split("~");
      const featId = res[0];
      const searchTerm = res[1];

      if (featId in resultsMap) {
        resultsMap[featId].push(searchTerm);
      } else {
        resultsMap[featId] = [searchTerm ];
      }
    });

    // Show a maximum of 10 search results.
    var numResults = 0;
    for (var fid in resultsMap) {
      numResults++;

      var feature = map.data.getFeatureById(fid);
      if (group_slugs.indexOf(fid) < 0) {
        var locationLink = createLocationLink(
          fid, feature.getProperty("url_slug"), feature.getProperty("title"), "search-results");
      }
      else {
        var locationLink = createGroupLink(feature);
      }

      // Add text of the form "(includes xxx, yyy)" when showing the result.
      var includes = [];
      var showIncludes = true;
      resultsMap[fid].forEach(function(index) {
        // Index 0 is just the name of the building. If it matches on its own,
        // don't show anything else.
        if (index == 0) {
          showIncludes = false;
        } else if (showIncludes) {
          includes.push(feature.getProperty("search")[index]);
        }
      });
      if (showIncludes) {
        var includesText = " (includes " + includes.join(", ") + ")";
        locationLink.textContent = locationLink.textContent + includesText;
      }
      document.querySelector("#search ul").append(locationLink);

      if (numResults == 10) {
        break;
      }
    }

    // If one of the links is to the currently selected location, mark it as such.
    styleLinksToSelectedPlace(true);
  } else {
    // We have no results.
    document.querySelector("#search ul").append(createLocationLink("", "", "No results"));
  }

  // Update the URL to link to this search.
  updateURL();
}

/*
 * Determine whether the search term has changed in such a way that we consider
 * a new search to be in progress.
 */
function updateActiveSearch() {
  // If new term is a substring of old term, do not update old term and do not
  // change whether a search is in progress (will be true if old term was
  // manually entered, false if it came from URL).
  var oldTerm = map.search.term;
  var newTerm = map.params.search
  if (oldTerm != "") {
    var index = oldTerm.indexOf(newTerm);
    if ((index == 0) || (index > 0 && (index + newTerm.length) == oldTerm.length)) {
      return;
    }
  }

  // Otherwise, the user has typed something, so they must be searching.
  map.search.active = true;
  map.search.term = newTerm;
}

/**
 * Turns off all the selection highlighting for the currently selected feature.
 */
function deselectFeature() {
  if (!map.params.feature) {
    return;
  }
  map.marker.setVisible(false);
  if (map.params.feature.getProperty("label") !== undefined) {
    map.params.feature.getProperty("label").unpin();
    map.params.feature.getProperty("label").ungroup();
  }
  map.data.revertStyle(map.params.feature);
  map.params.feature.setProperty("highlighted", false);

  // Un-highlight any ancillary features.
  var includedFeatures = map.params.feature.getProperty("ancillaries");
  if (includedFeatures !== undefined) {
    includedFeatures.forEach(function (fid) {
      var feature = map.data.getFeatureById(fid);
      map.data.overrideStyle(feature, buildingStyleOverride("inactive"));
      feature.setProperty("highlighted", false);
    });
  }

  styleLinksToSelectedPlace(false);

  map.params.feature = null;
}

function deselectFeatures() {
  if (!map.params.features) {
    if (map.params.feature) {
      deselectFeature();
    }
    return;
  }
  for (i in map.params.features) {
    map.params.feature = map.params.features[i];
    if (map.params.feature.marker) {
      map.params.feature.marker.setVisible(false);
    }
    deselectFeature();
  }
  map.params.features = null;
}

/**
 * Given a feature, highlight it, display related content, and update the URL.
 * On init, the marker and highlight may already be set correctly, but it's
 * easier to redo than detect this.
 */
function selectFeature(feature, shouldUpdateURL, shouldMoveMarker=true, shouldDeselectOthers=true) {
  var featureId = feature.getId();
  // If the user selects an ancillary feature, pretend they selected the primary instead.
  if (feature.getProperty("primary") !== undefined) {
    featureId = feature.getProperty("primary");
    feature = map.data.getFeatureById(featureId);
  }

  // The current feature might have come from the URL, and we don’t want an ancillary there.
  if (map.params.feature && map.params.feature.getProperty("primary") !== undefined) {
    featureId = map.params.feature.getProperty("primary");
    feature = map.data.getFeatureById(featureId);
    shouldUpdateURL = true;
  }

  // If there's a previously selected feature, turn off its marker, label, and highlight.
  if (shouldDeselectOthers && map.params.feature && feature != map.params.feature) {
    deselectFeature();
  }

  if (shouldDeselectOthers && map.params.features) {
    deselectFeatures();
  }

  // Officially record the correct selection.
  map.params.feature = feature;

  // Make sure the marker is showing on the building. This is already true if
  // it was selected via URL, but it’s easier to redo than test.
  if (shouldMoveMarker) {
    showMarker(feature);
  }

  // Change URL to reflect map status.
  if (shouldUpdateURL) {
    // Update the map center to correspond to the selected feature.
    map.setCenter(map.marker.getPosition());
    updateURL();
  }

  // Hide the places and options lists if they're open.
  closeMenu(document.getElementById("places-link"), document.getElementById("places"));
  closeMenu(document.getElementById("options-link"), document.getElementById("options"));

  // Show building content.
  updateContentSection(feature);

  // Highlight the selected feature.
  map.data.overrideStyle(feature, buildingStyleOverride("highlight"));
  feature.setProperty("highlighted", true);

  // Highlight any ancillary features we know about.
  var includedFeatures = feature.getProperty("ancillaries");
  if (includedFeatures !== undefined) {
    includedFeatures.forEach(function(fid) {
      feature = map.data.getFeatureById(fid);
      map.data.overrideStyle(feature, buildingStyleOverride("highlight"));
      feature.setProperty("highlighted", true);
    });
  }

  styleLinksToSelectedPlace(true);
}

function selectFeatures(groupfeature) {

  var features = [];
  var marked_features = []; // Features with a pin in them
  var desired_markers = groupfeature.getProperty("markers");
  var building_ids = groupfeature.getProperty("buildings");
  for (i in building_ids) {
    var feature = map.data.getFeatureById(building_ids[i]);
    features.push(feature);
    // If markers were defined, check if this ID is on the list
    if (desired_markers.length > 0){
      if (desired_markers.indexOf(building_ids[i]) >= 0)
        marked_features.push(feature)
    }
  }
  // deselect any selected features first
  if (map.params.feature) {
    deselectFeature();
  }
  if (map.params.features && map.params.features != features) {
    deselectFeatures();
  }

  // Setup to change the map focus area to include all selected buildings
  var bounds = new google.maps.LatLngBounds();

  for (i in features) {
    var f = features[i];
    selectFeature(f, false, shouldDeselectOthers=false, shouldMoveMarker=false);
    var pt = f.getProperty("click_point")
    var position = new google.maps.LatLng(pt.coordinates[1], pt.coordinates[0]);

    if (marked_features.indexOf(f) >= 0) {
      f.marker = new google.maps.Marker({
          position: position,
          map: map
      });
      map.params.feature.getProperty("label").pin();
    }
    map.params.feature.getProperty("label").group();
    bounds.extend(position);
  }
  map.params.feature = null;
  map.params.features = features;
  map.params.group = groupfeature;
  map.fitBounds(bounds);

  // Change URL to reflect map status.
  updateURL();
}

/**
 * Set the marker in a feature.
 */
function showMarker(feature) {
  var pt = feature.getProperty("click_point");
  map.marker.setPosition(new google.maps.LatLng(pt.coordinates[1], pt.coordinates[0]));
  map.marker.setVisible(true);
}

/**
 * Highlight the links to the currently selected feature. If highlinkLinks
 * is true, it will add a class to the current feature; if false, it will
 * clear the styling on those links.
 */
function styleLinksToSelectedPlace(highlightLinks) {
  if (!map.params.feature) {
    return;
  }

  var linksClass = ".link-feat-" + map.params.feature.getProperty("url_slug");
  if (highlightLinks) {
    document.querySelectorAll(linksClass).forEach(function (linkElement) {
      linkElement.classList.add("selected-link");
    });
  } else {
    document.querySelectorAll(linksClass).forEach(function (linkElement) {
      linkElement.classList.remove("selected-link");
    });
  }
}

/**
 * Get the information about a building to be displayed in the info window.
 */
function updateContentSection(feature) {
  // Clear the wrapper width so we don't affect the content title.
  document.getElementById("hover-wrapper").style.width = "";

  var title = feature.getProperty("title");
  var description = feature.getProperty("description");
  var links = feature.getProperty("links");

  // Hide the extra content automatically on smaller screens, and show it on larger ones.
  if (document.body.clientWidth < 860) {
    document.getElementById("place-content").classList.add("closed");
    document.getElementById("place-content").classList.remove("open");
    document.getElementById("toggle-info").textContent = "More Info";
    document.getElementById("more-content").style.display= "none";
  } else {
    document.getElementById("place-content").classList.add("open");
    document.getElementById("place-content").classList.remove("closed");
  }
  document.getElementById("content-title").innerHTML = title;
  document.querySelector("#place-content #description").innerHTML = description;

  // Add all links.
  document.querySelector("#place-content ul").replaceChildren(); // Get rid of existing links.
  for (var title in links) {
    createContentLink(title, links[title] /* url */);
  }

  // TODO: transitions
  // $("#place-content").slideDown();
  document.getElementById("place-content").style.display = "block";

  // Match the width of the wrapper to the title, so that hovering over empty
  // space doesn't trigger the color change. We do this after the place content
  // is visible because the div width doesn't affect positioning, but the width
  // calculation is wrong if the content title isn't visible.
  // We add a little extra padding to the title to avoid colision with the
  // close icon.
  setWidth(document.getElementById("hover-wrapper"), document.getElementById("content-title").offsetWidth)
  document.getElementById("content-title").style.paddingRight = "2em";
}

var map;

/**
 * Initialize the map. Called when the Google API script loads.
 */
function initMap() {
  // These layers are separate geojson files and most can be controlled by
  // a checkbox in the Options panel or a URL parameter.
  var layers = ["blue_lights", "bus_stops", "entrances", "parking", "inactive_buildings", "no_smoking"];

  // Temporarily store the parsed map status here until the map object exists.
  var urlParams = parseURL(layers);

  map = new google.maps.Map(document.getElementById('map'), {
    zoom: urlParams.zoom,
    center: urlParams.center,
    styles: makeMapStyle(),
    // Move the satellite/roadmap switcher to the bottom left.
    mapTypeControlOptions: {
      mapTypeIds: [
        'roadmap',
        'satellite'
      ],
      position: google.maps.ControlPosition.BOTTOM_LEFT
    },
    // Never show the fullscreen control, since the map is always fullscreen.
    fullscreenControl: false
  });

  // Set starting config based on URL (or defaults).
  map.search = urlParams.return.search;

  map.params = urlParams;

  // The unique marker (pin) object.
  map.marker = new google.maps.Marker({map: map});

  // Create a custom overlay layer to hold labels.
  HtmlOverlay.prototype = new google.maps.OverlayView();
  addOverlayMethods(HtmlOverlay);

  // Collect a list of completed Ajax processes so we can test in mergeMapContent():
  var ajaxCompleted = {
    geodata: false,
    content: false
  };

  // Set a custom flag to check that the map is fully loaded. Mostly used in map testing.
  map._fullyloaded = false;

  // The buildings get loaded into the default data layer.
  map.data.loadGeoJson('/js/current/buildings.json', {}, function mapDataLoaded() {
    // Set the visual style of the buidings.
    var style = buildingStyleDefault();
    map.data.setStyle(style);

    // If we’re starting with a place identified in the URL, set its marker and
    // style. The label and place info will be added once building content is loaded.
    if (map.params.fid) {
      var feature = map.data.getFeatureById(map.params.fid);
      showMarker(feature);
      map.data.overrideStyle(feature, buildingStyleOverride("highlight"));
      map.params.feature = feature;
      // Remove map.params.fid now that we've used it.
      delete map.params.fid;
    }

    // Now it's safe to run mergeMapContent().
    ajaxCompleted.geodata = true;
    mergeMapContent();
  });

  // Don't load building content until we have all the tiles.
  var buildingContent = null;
  var groupContent = null;

  var tilesListener = map.addListener("tilesloaded", async function () {
    async function loadBuildingContent() {
      const response = await fetch('/js/building_content.json');
      const body = await response.json();
      return body;
    }
    async function loadGroupContent() {
      const response = await fetch('/js/groups.json');
      const body = await response.json();
      return body;
    }

    try {
      const results = await Promise.all([loadBuildingContent(), loadGroupContent()])
      // do other actions
      buildingContent = results[0];
      groupContent = results[1];
      ajaxCompleted.content = true;
      mergeMapContent();
      // Wait 3/10 of a second for Google’s js to run, then do some a11y stuff.
      // TODO: Can we test something for readiness instead of a timeout?
      setTimeout(function(){
        accessibilityShim();
      }, 300);
    } 
    catch (ex) { }
    finally {
      // Remove the listener once the first event has fired.
      tilesListener.remove();
    }
  });

  // Attempt to show the user's location on the map.
  locateUser();

  layers.forEach(function(layer) {
    map[layer] = new google.maps.Data()
    map[layer].loadGeoJson("/js/current/" + layer + ".json", {}, function() {
      switch(layer) {
        case "inactive_buildings":
          map.inactive_buildings.loadGeoJson("/js/current/open_street_map.json", {}, layerDataLoaded(layer));
          break;
        case "parking":
          map.parking.loadGeoJson("/js/current/lots.json", {}, layerDataLoaded(layer));
          break;
        default:
          layerDataLoaded(layer);
      }
    });
  });

  /*
   * Helper function to wrap up layer data once it's loaded.
   */
  function layerDataLoaded(layer) {
    initLayerStyle(layer);
    map[layer].setMap(map);
    if (layer != "inactive_buildings") {
      // Set a listener on the corresponding option checkbox to toggle the layer
      // visibility when clicked, and record the selection in GTM dataLayer.

      document.querySelector("#opt-" + layer).addEventListener('click', (event) => {
        setLayerVisibility(
          event.target.id.slice(4),                         // name of layer that was clicked (slice off "opt-")
          document.getElementById(event.target.id).checked, // current status of checkbox - determines visibility
          true                                              // whether to update the URL
        );
        if (document.getElementById(event.target.id).checked) {
          // Option is selected; register this with GTM.
          (window.dataLayer = window.dataLayer || []).push({
            'event': "menuEngagement",
            menuEngagement: {
              name: "options",
              action: "selection",
              value: getSelectedOptions(),
              data: ""
            }
          });
        }
      });
    }
  }

  /*
   * Once we have both geodata and content for the interactive features,
   * we can merge them into a single data structure and do the setup
   * stuff that requires both.
   */
  function mergeMapContent() {
    // Don’t run unless both halves are done.
    if (!ajaxCompleted.geodata || !ajaxCompleted.content) { return; }

    var searchList = [];

    // Process the content we received.
    processLocations(buildingContent, searchList);
    processGroups(groupContent, searchList);

    // Initialize our search index.
    initSearch(searchList);

    // Create the quick search links.
    createQuickSearchLinks();

    // Run full feature selection code if something was in the URL.
    if (map.params.feature) {
      selectFeature(map.params.feature, false);
      map.params.feature.getProperty("label").pin();
    }

    // Select multiple features if a group was in the URL
    if (map.params.gid) {
      var group = map.data.getFeatureById(map.params.gid);
      map.params.group = group;
      delete map.params.gid;
      selectFeatures(group);
      updateContentSection(group);
    }

    // Run the search if there’s one specified in the URL.
    if (map.params.search) {
      document.getElementById("searchbox").value = map.params.search;
      performSearch(map.params.search);
    }

    // Initialize UI elements.
    initListeners();

    // Add our custom controls div to the map.
    var mhcControls = document.getElementById("mhc-controls");
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(mhcControls);

    var copyright = document.getElementById("copyright");
    map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(copyright);

    initFeedbackControl();

    // Enable interaction (this flag is mostly used for testing).
    map._fullyloaded = true;
  }
}

/**
 * Add the feedback form link to the map as a control and set up associated
 * listeners.
 */
function initFeedbackControl() {
  var header = document.querySelector("#mhc-feedback .header");
  var full = document.querySelector("#mhc-feedback .full");

  // Make sure the mhc controls content appears above the feedback form.
  document.getElementById("mhc-controls").style.zindex = 1;

  // Set up interactivity.
  header.addEventListener('click', () => {
    if (isVisible(full)) {
      full.style.display = "none";
    }
    else {
      full.style.display = "block";
    }
    var fullVisible = isVisible(full);

    // Position the "feedback" tab next to the full text.
    // We clear all of these settings when hiding the full feedback div.
    header.style.transformorigin = fullVisible ? "0 0" : "";
    header.style.top = fullVisible ? "1.1em" : "";
    header.style.height = fullVisible ? "1.5em" : "";
    header.style.left = fullVisible ? "0.9em" : "";
  });


  // Add this control to the map above the "Google" logo image in the lower
  // left.
  var feedbackControl = document.getElementById("mhc-feedback");
  map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(feedbackControl);
}

/*
 * Set up listeners.
 */
function initListeners() {
  /*** Map event listeners ***/

  // Map click listener
  map.data.addListener('click', function(event) {
    selectFeature(event.feature, false /* shouldUpdateURL */);
    // UpdateURL outside selectFeature so we don't recenter the map on click.
    updateURL();
    map.params.feature.getProperty("label").pin();
    // If there is an active search but user clicked a map feature instead,
    // record a search abandonment event.
    if (map.search.active) {
      (window.dataLayer = window.dataLayer||[]).push({
        'event': "searchEngagement",
        searchEngagement: {
          action: "abandon",
          data: map.search.term
        }
      });
    }
    // Regardless of active search, record that the user selected a place.
    (window.dataLayer = window.dataLayer||[]).push({
      'event': "mapEngagement",
      mapEngagement: {
        action: "map_click",
        value: event.feature.getProperty("url_slug"),
        data: getSelectedOptions()
      }
    });
  });

  // Turn off building colors when in satellite view
  map.addListener("maptypeid_changed", function() {
    var style = buildingStyleDefault();
    map.data.setStyle(style);
  });

  // Parking visibility needs to be recalculated when the zoom changes.
  map.addListener("zoom_changed", function() {
    // Reset the style function so that the visibility is recalculated.
    map.params.zoom = map.getZoom();
    map.parking.setStyle(
      parkingStyleFunction.bind(null, map.params.options["parking"] /* layerVisible */));
    showLabelsByZoom();
  });

  // Hide everything when street view is open.
  var streetView = map.getStreetView();
  streetView.addListener("visible_changed", function() {
    toggleOptionsForStreetView(streetView.getVisible());
  });


  /*** Content listeners ***/

  // Menu toggle listeners
  document.getElementById("places-link").addEventListener('click', () => {
    // Toggle places, close options
    togglePlacesAndOptionsMenus("places", "search_places")

    if (isVisible(document.getElementById("places"))) {
      // Places menu is open; register this with GTM.
      (window.dataLayer = window.dataLayer || []).push({
        'event': "menuEngagement",
        menuEngagement: {
          name: "search_places",
          action: "menu_open",
          value: "",
          data: ""
        }
      });
    }
  });

  document.getElementById("options-link").addEventListener('click', () => {
    // Toggle options, close places
    togglePlacesAndOptionsMenus("options", "options")

    if (isVisible(document.getElementById("options"))) {
      // Options menu is open; register this with GTM.
      (window.dataLayer = window.dataLayer || []).push({
        'event': "menuEngagement",
        menuEngagement: {
          name: "options",
          action: "menu_open",
          value: "",
          data: ""
        }
      });
    }
  });

  // Category heading toggle listeners
  // Identifiers for each of the category headings in the places list.
  var submenus = ["common-searches", "academic", "residence", "other", "poi"];
  submenus.forEach(function(category) {
    document.querySelector(`#${category} h2`).addEventListener('click', (event) => {
      // which in this case the is the category ID.
      var categoryDiv = document.querySelector(`#${event.currentTarget.parentElement.id}`);

      // Show or hide the list of places under this category.
      if (!(categoryDiv.classList.contains("open"))) {
        // Open it.
        toggleCategorySubmenu(categoryDiv, true);

        // Submenu is open; register this with GTM.
        (window.dataLayer = window.dataLayer||[]).push({
          'event': "menuEngagement",
          menuEngagement: {
            name: "search_places",
            action: "submenu_open",
            value: event.currentTarget.parentElement.id,
            data: ""
          }
        });
      } else {
        // Close it.
        toggleCategorySubmenu(categoryDiv, false);
      }

      // Close any other submenus that were open.
      document.querySelectorAll(`.content-links.open:not(#${category})`).forEach(function(el, i) {
        toggleCategorySubmenu(el, false);
      });
    });
  });

  // Clicking the title of a place toggles the full description.
  document.querySelector(`#place-content h2`).addEventListener('click', () => {
    placeContentToggle();
  });
  document.querySelector(`#toggle-info`).addEventListener('click', () => {
    placeContentToggle();
  });

  function placeContentToggle() {
    // When clicked, the place title toggles the full description.
    if (document.getElementById("place-content").classList.contains("closed")) {
      document.getElementById("toggle-info").textContent = "Less Info";
      // TODO transitions
      document.getElementById("more-content").style.display = "";
    } else {
      document.getElementById("toggle-info").textContent = "More Info";
      // TODO transitions
      document.getElementById("more-content").style.display = "none";
    }

    document.getElementById("place-content").classList.toggle("closed");
    document.getElementById("place-content").classList.toggle("open");
  }
  // The close button will close the content pane entirely and unselects the feature.
  document.querySelector(`#close-button`).addEventListener('click', () => {
    // TODO transitions
    document.getElementById("place-content").style.display = "none";
    document.getElementById("place-content").classList.toggle("closed");
    document.getElementById("place-content").classList.toggle("open");
    deselectFeatures();
    updateURL();
  });


  /*** Search listener ***/

  // Perform a search when the user types in the search box.
  document.getElementById("searchbox").addEventListener("input", function () {
    var term = document.getElementById("searchbox").value;
    if (term !== "") {
      // We have a term, so search.
      performSearch(term);
      // Keep track of active search for GTM.
      updateActiveSearch();
      document.getElementById("places").style.height = "100%";
    } else {
      // Flush out everything from the previous search.
      abandonSearch();
    }
  });


  /*** Copyright listener ***/

  // Open/close the building data copyright info box.
  document.querySelector("#copyright .header").addEventListener('click', () => {
    var content = document.querySelector("#copyright .content");

    if (isVisible(content)) {
      content.style.display = "none";
    }
    else {
      content.style.display = "block";
    }
    document.querySelector("#copyright .header .fas").classList.toggle("fa-chevron-down");
    document.querySelector("#copyright .header .fas").classList.toggle("fa-chevron-up");
  });
}

/**
 * Attempt to locate the user on the map.
 */
function locateUser() {
  if (navigator.geolocation) {
    // Init the marker we use to show the user.
    map.userMarker = new google.maps.Marker({ // google.maps.MarkerOptions
      clickable: false,
      map: map,
      visible: false,
      icon : { url: "/img/geolocation_icon.png" }
    });

    // Update the marker's position when the user's location changes.
    navigator.geolocation.watchPosition(function(location) {
      // This code executes if we successfully gain access to the user's location.
      map.params.watchingPosition = true;

      map.userMarker.setPosition({ // google.maps.LatLngLiteral
        lat: location.coords.latitude,
        lng: location.coords.longitude
      });

      // Show the marker, but only if street view isn't open.
      if (!map.userMarker.getVisible() && !map.getStreetView().getVisible()) {
        map.userMarker.setVisible(true);
      }
    });
  }
}

/**
 * If street view is currently visible, hide any layers or markers that
 */
function toggleOptionsForStreetView(streetViewVisibility) {
  // Note: We need to account for the situation where we get repeated calls with
  // "true", since panorama changes may also trigger the visibility change
  // event.
  if (streetViewVisibility) {
    // If any options are showing, hide them. (We don't have to do this for
    // buildings, since right now it seems like they're not visible in street
    // view anyways.)
    for (var opt in map.params.options) {
      // Hide the layer if it's visible.
      if (map.params.options[opt]) {
        setLayerVisibility(opt, false /* visibility */, false /* shouldUpdateURL */);

        // We need to maintain the layer's status in map.params.options, since
        // that's what we restore them from when the user exits street view.
        map.params.options[opt] = true;
      }
    }

    // If the selected feature marker is visible, hide it.
    if (map.marker.getVisible()) {
      map.marker.setVisible(false);
    }

    // Hide geolocation icon.
    if (map.params.watchingPosition) {
      map.userMarker.setVisible(false);
    }
  } else {
    // If any options should be visible, show them.
    for (var opt in map.params.options) {
      if (map.params.options[opt]) {
        setLayerVisibility(opt, true /* visibility */, false /* shouldUpdateURL */);
      }
    }

    // If there's a building selected, show the marker.
    if (map.params.feature) {
      map.marker.setVisible(true);
    }

    // Show geolocation icon, if we ever got a location for it.
    if (map.params.watchingPosition) {
      map.userMarker.setVisible(true);
    }
  }
}

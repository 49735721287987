// Accessible parking spaces have been pre-processed into clusters. Certain
// spaces represent special cases, in which we would like the clustered
// space to be visible at or below a certain zoom, and the original non-clustered
// spaces to be visible at or above a certain zoom. The higher the zoom number,
// the more zoomed in the map is.
//
// Do NOT specify a minimum or maximum if none exists.
var parkingVisibility = {
	"mary_woolley_circle_accessible_centroid": {
		"max_zoom": 17
	},
	"mary_woolley_circle_1": {
		"min_zoom": 18,
	},
	"mary_woolley_circle_2": {
		"min_zoom": 18,
	},
	"abbey_buck_south_accessible_centroid": {
		"max_zoom": 17
	},
	"abbey_buck_south_0": {
		"min_zoom": 18,
	},
	"abbey_buck_south_1": {
		"min_zoom": 18,
	}
}

/**
 * Given the feature ID of a parking space, returns whether or not it should be
 * visible at the current map zoom.
 */
function getParkingVisibility(fid) {
	// If the parking layer isn't on, none of the spaces should be visible.
	if (!map.params.options.parking) {
		return false;
	}

	// We only want to check the visibility if we have a special case.
	if (!parkingVisibility[fid]) {
		return true;
	}

	if ("min_zoom" in parkingVisibility[fid]
	  && map.params.zoom < parkingVisibility[fid].min_zoom) {
		return false;
	}
	if ("max_zoom" in parkingVisibility[fid]
		&& map.params.zoom > parkingVisibility[fid].max_zoom) {
		return false;
	}

	return true;
}
// module.exports = {
// 	parkingVisibility
// }
// export { parkingVisibility };

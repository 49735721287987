/**
 * WARNING: This file is generated by bin/process_url_slugs.py
 * Anything written to this file will be overwritten the next
 * time that the building content is processed.
 *
 * To change any URL slugs, change the corresponding entry in
 * the building content.
 */

var url_slugs = {
	"1-faculty": "building_M24",
	"1-woodbridge": "building_M20",
	"17-morgan": "building_H20",
	"1837": "building_D15",
	"25-morgan": "building_G09",
	"3-park": "building_H23",
	"57-college": "building_M08",
	"8-park": "building_H24",
	"92-college": "building_H13",
	"94-college": "building_H14",
	"abbey": "building_D11",
	"ace-house": "building_M18",
	"admission": "building_G32",
	"amphitheater": "building_G24",
	"amphitheatre-seating": "building_G24.X",
	"amphitheatre-shed": "building_G24.1",
	"art": "building_G25",
	"betty-shabazz": "building_G06",
	"boathouse": "building_M31",
	"brigham": "building_D12",
	"buckland": "building_D13",
	"canoe": "building_G26",
	"carr": "building_G03",
	"cdc": "building_40",
	"chapel": "building_G01",
	"ciruti": "building_G11",
	"clapp": "building_G05",
	"cleveland": "building_G03.1",
	"communitycenter": "building_G02",
	"creighton": "building_D28",
	"dickinson": "building_D14",
	"dining": "building_F30",
	"dwight": "building_G07",
	"eliot": "building_G08",
	"equestrian": "building_G29",
	"facilities": "building_M05",
	"field-gate": "field_gate",
	"gorse": "building_M15",
	"greenhouse": "building_G21",
	"ham": "building_D16",
	"health": "building_G15",
	"jeannette-marks": "building_M16",
	"kendade": "building_G33",
	"kendall": "building_G10",
	"library": "building_G22",
	"macgregor": "building_D17",
	"mail-copy": "building_M01",
	"mary-lyon": "building_G13",
	"mary-lyons-grave": "mary_lyons_grave",
	"mary-woolley": "building_G14",
	"mead": "building_D19",
	"merrill": "building_G30",
	"north-delle": "building_D18",
	"north-rocky": "building_D24",
	"observatory": "building_G23",
	"odyssey": "building_VC2",
	"orchards": "building_Orchards",
	"ortega": "building_G04",
	"pageant-green": "pageant_green",
	"pearsons": "building_D21",
	"pearsons-annex": "building_D20",
	"porter": "building_D22",
	"pratt": "building_G17",
	"president": "building_M06",
	"project-stream": "project_stream",
	"prospect": "building_D23",
	"public-safety": "building_G28",
	"reese": "building_G18",
	"rooke": "building_G12",
	"safford": "building_D25",
	"shattuck": "building_G19",
	"skinner": "building_G20",
	"skinner-green": "skinner_green",
	"skinner-museum": "building_M11",
	"skinner-school": "building_M12",
	"south-delle": "building_D18.1",
	"south-rocky": "building_D24.1",
	"torrey": "building_D26",
	"wilder": "building_D27",
	"willits": "building_A54"
}

var redirect_slugs = {
	"aux-services": "mail-copy",
	"blanchard": "communitycenter",
	"campus-police": "public-safety",
	"ortega-banteah": "ortega"
}

var group_slugs = [
	"lits",
	"printers"
]
